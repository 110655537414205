$btn-primary: #6c3300;
$btn-secondary: #ffffff;
.section-newlaunch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 25px;
    text-align: center;
    @media (max-width: 768px) {
      width: 90%;
    }
    .title {
      margin: 0;
      color: #180054;
      font-size: 2.2rem;
      font-weight: 500;
      font-family: "Montserrat";
      width: 90%;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 1.8rem;
        text-align: center;
      }
    }
    .img-desktop {
      object-fit: cover;
      border-radius: 25px;
      width: 100%;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .img-mobile {
      display: none;
      object-fit: cover;
      border-radius: 25px;
      width: 100%;
      @media (max-width: 768px) {
        display: block;
      }
    }
    .btn-about {
      font-family: "Open Sans";
      font-weight: 500;
      background-color: $btn-primary;
      border: 1px solid $btn-primary;
      border-radius: 9px;
      padding: 9px 25px;
      cursor: pointer;
      color: $btn-secondary;
      transition: 0.5s ease-in-out;
      font-size: 0.9rem;

      &:hover {
        background-color: transparent;
        transition: 0.5s ease-in-out;
        color: $btn-primary;
      }
    }
  }
}
