.section-tenants {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @media (max-width: 768px) {
    gap: 16px;
  }
  .container-tenants {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    gap: 40px;
    @media (max-width: 768px) {
      width: 90%;
    }
    .title {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 3.812rem;
      color: #180054;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
    .logos {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      img {
        width: 100%;
      }
    }
  }
}
