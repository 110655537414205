.section-project {
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 90%;
    align-self: center;
    height: auto;
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: #141318;
    border-radius: 32px;
    padding: 65px 0;
    align-items: center;
    justify-content: center;
    gap: 0 2rem;
    @media (max-width: 768px) {
      width: 100%;
      padding: 1rem 0;
      align-items: center;
    }
    .title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 3.812rem;
      color: #cfc3ff;
      text-transform: uppercase;
      margin: 0 0 40px 0;
      @media (max-width: 768px) {
        font-size: 2rem;
        margin: 0 0 20px 0;
      }
    }
    .project {
      width: 90%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 32px 20px;
      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
      .project-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        text-align: start;
        @media (max-width: 768px) {
          gap: 5px;
        }
        .project-img {
          width: 100%;
          object-fit: cover;
          border-radius: 9px;
          height: 250px;
          @media (max-width: 768px) {
            height: 150px;
          }
        }
        .project-title {
          font-family: "Open Sans";
          color: #fef9ff;
          font-size: 1rem;
          font-weight: 400;

          @media (max-width: 768px) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
