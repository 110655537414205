$text-primary: #180054;
$text-secondary: #1c1b20;
$icon-secondary: #382778;
$icon-primary: #f9f3ff;
.section-surrounding {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-surrounding {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 40px 0;
    @media (max-width: 768px) {
      width: 90%;
      padding: 16px 0;
    }
    .title {
      margin: 0;
      font-size: 3.813rem;
      font-weight: 500;
      font-family: "Montserrat";
      text-transform: uppercase;
      color: $text-primary;
      @media (max-width: 768px) {
        font-size: 1.813rem;
        text-align: center;
      }
    }
    .container-image {
      width: 100%;
      margin: 40px 0 15px 0;
      .image {
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
      }
    }
    .container-fasilitas {
      display: flex;
      gap: 20px;
      width: 100%;
      font-family: "Open Sans";
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        justify-content: start;
      }
      .point {
        width: fit-content;

        .fasilitas {
          display: flex;
          align-items: center;
          gap: 6px;
          margin-bottom: 15px;
          font-size: 1.2rem;
          @media (max-width: 768px) {
            font-size: 1rem;
          }
          .icon-fasilitas {
            width: 20px;
            height: 20px;
            background-color: $icon-secondary;
            fill: $icon-primary;
            padding: 6px;
            border-radius: 50%;
          }
        }
        .loc {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 1rem;
          font-family: "Open Sans";
          color: $text-secondary;
          fill: $text-secondary;
          margin-bottom: 9px;
          @media (max-width: 768px) {
            font-size: 0.8rem;
          }
          .icon-loc {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
