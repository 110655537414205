$primary-text: #fef9ff;
$secondary-text: #cfc3ff;
.hero-pages {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  height: 90vh;
  @media (max-width: 768px) {
    height: 70vh;
  }
  .hero-pages-img {
    position: absolute;
    height: 90vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    @media (max-width: 768px) {
      height: 70vh;
    }
  }
  .container {
    position: relative;
    z-index: 1;
    width: 50%;
    padding: 65px 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding: 15px 0;
      width: 80%;
      align-items: end;
    }
    .box {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 50%;
      padding: 40px;
      border-radius: 40px;
      background: linear-gradient(
          145deg,
          #fdf8ff84,
          #170053cb,
          #180053cb,
          #180053cb,
          #fdf8ff84
        )
        border-box;
      border: solid 10px #fdf8ff40;
      @media (max-width: 768px) {
        padding: 20px 0 20px 20px;
        border-radius: 20px 0 0 20px;
        width: 90%;
        border-top: solid 5px #fdf8ff40;
        border-right: none;
        border-bottom: solid 5px #fdf8ff40;
        border-left: solid 5px #fdf8ff40;
      }
      .caption {
        color: $primary-text;
        font-size: 1.25rem;
        font-family: "Montserrat";
        font-weight: 500;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
      .title {
        color: $secondary-text;
        font-size: 2.8rem;
        font-family: "Montserrat";
        font-weight: 400;

        margin: 0;
        @media (max-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }
  }
}
