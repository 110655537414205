$dark-scheme-primary: #180054;
$dark-scheme-milk: #1c1b20;
$dark-scheme-maize: #382778;
$dark-scheme-bg: #001b08;
$icon-spec: #382778;
$btn-primary: #004e20;
$btn-secondary: #ffffff;
$btn-hover: #00873c;

.card {
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 15.5px);
  border-radius: 12px;
  border: 1px solid #382778;
  @media (max-width: 1024px) {
    width: calc(100% / 2 - 12px);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  .slick-slider {
    width: 100%;
    .container-img {
      height: 400px;
    }
    .card-img {
      border-radius: 12px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .slick-next::before,
    .slick-prev::before {
      font-family: "slick";
      font-size: 30px;
      color: $dark-scheme-maize;
    }
    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
    }

    .slick-dots {
      position: absolute; /* Make dots absolute for positioning within slides */
      bottom: 10px; /* Adjust vertical positioning */
      left: 50%; /* Center dots horizontally */
      transform: translateX(-50%); /* Center dots precisely */
      z-index: 1; /* Ensure dots are above image content */
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    .slick-dots {
      li {
        margin: 0;
      }
    }
  }
  .card-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 12px;
    border-radius: 0 0 9px 9px;
    text-align: start;
    margin-top: -5px;

    .card-title {
      font-family: "Montserrat";
      font-weight: 500;
      color: $dark-scheme-milk;
      font-size: 1.7rem;
      margin: 0 0 4px 0;
    }
    .card-sub {
      font-family: "Open Sans";
      font-size: 1.1rem;
    }
    .gridspek {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: #504e4f;
      font-family: "Open Sans";
      font-weight: 500;
      margin: 6px 0 15px 0;
      .spek-item {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 1rem;
        color: $dark-scheme-milk;
        .icon {
          height: 20px;
          width: 20px;
          color: $icon-spec;
        }
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .card-price {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 6px 0 0 0;
      margin-bottom: 15px;
      border-top: solid 1px #1c1b2026;
      font-family: "Open Sans";
      color: $dark-scheme-milk;
      .ket {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 1.1rem;
      }
      span {
        font-size: 2.188rem;
        font-weight: 500;
        color: $dark-scheme-primary;
        font-family: "Montserrat";
      }
    }
    .container-btn {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .btn-details {
        display: flex;
        font-family: "Open Sans";
        font-weight: 500;
        border-radius: 9px;
        padding: 9px 0;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        background-color: #6c3300;
        color: #ffffff;
        border: none;
        transition: 0.5s ease-in-out;
        font-size: 0.9rem;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #b85b00;
        }
        .icon {
          margin-right: 9px;
          width: 21px;
          height: 21px;
        }
      }
      .btn {
        display: flex;
        font-family: "Open Sans";
        font-weight: 500;
        border-radius: 9px;
        padding: 9px 0;
        cursor: pointer;
        width: 100%;
        background-color: $btn-primary;
        color: $btn-secondary;
        border: none;
        transition: 0.5s ease-in-out;
        font-size: 0.9rem;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: $btn-hover;
        }
        .icon {
          margin-right: 9px;
          width: 21px;
          height: 21px;
        }
      }
    }
  }
}
