.aboutcont {
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  .center {
    width: 80%;
    @media (max-width: 768px) {
      width: 90%;
    }

    .img {
      width: 100%;
      object-fit: cover;
      object-position: bottom;
      height: 450px;
      border-radius: 25px;
      @media (max-width: 768px) {
        height: auto;
      }
    }

    .abouttile {
      margin-top: 45px;
      color: #6c3300;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 400;
      @media (max-width: 768px) {
        margin-top: 25px;
      }
    }
    .aboutcontent {
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .title {
        width: 50%;
        color: #180054;
        font-family: "Montserrat", sans-serif;
        font-size: 62px;
        font-weight: 500;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 30px;
          margin: 6px 0 9px 0;
        }
      }
      .subtitle {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
        }
        .text {
          color: #1c1b20;
          font-family: "Open Sans", sans-serif;
          font-size: 1.2rem;
          font-weight: 400;
          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }
        .wabutton {
          padding: 9px 25px;
          border-radius: 9px;
          margin-top: 25px;
          cursor: pointer;
          background-color: #6c3300;
          color: white;
          border: none;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 500;
          transition: 0.2s ease-in;
          &:hover {
            background-color: #b85b00;
          }
        }
      }
    }
  }
}
