$primary-text: #fef9ff;
$secondary-text: #cfc3ff;
.section-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 7;
  .slider {
    width: 100%;
    img {
      width: 100%;
      height: 90vh;
      object-fit: cover;
      object-position: center;
      @media (max-width: 768px) {
        height: 80vh;
      }
    }
    .slick-slider {
      width: 100%;
      .container-img {
        height: 400px;
      }
      .card-img {
        border-radius: 12px;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
        color: #6c3300;
      }
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      .slick-dots {
        position: absolute; /* Make dots absolute for positioning within slides */
        bottom: 10px; /* Adjust vertical positioning */
        left: 50%; /* Center dots horizontally */
        transform: translateX(-50%); /* Center dots precisely */
        z-index: 1; /* Ensure dots are above image content */
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }
    }
  }
  .container-hero {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: fit-content;
    width: 80%;
    bottom: 4px;
    text-align: start;

    @media (max-width: 768px) {
      height: fit-content;
      width: 90%;
    }
    .hero-description {
      display: flex;
      flex-direction: column;
      gap: 5px 0;
      background-color: #180053a6;
      padding: 25px;
      border-radius: 25px 25px 0 0;
      text-align: center;
      width: 35%;
      @media (max-width: 768px) {
        width: 70%;
        padding: 15px;
      }
      .hero-title {
        color: $primary-text;
        font-size: 1.8rem;
        font-family: "Montserrat";
        font-weight: 500;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
      .hero-text {
        color: $secondary-text;
        font-size: 2.8rem;
        font-family: "Montserrat";
        font-weight: 400;

        margin: 0;
        @media (max-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }
  }
}
