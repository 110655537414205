@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
$btn-primary: #6c3300;
$btn-secondary: #ffffff;
$btn-hover: #b85b00;
$primary-text: #1c1b20;
$hover-text: #6c3300;
.navbar {
  background-color: #fbf9fa;
  height: 91px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: relative;
  box-shadow: 0 0 10px 2px #504e4f;
  top: 0;
  z-index: 999;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    .navbar-logo {
      color: $primary-text;
      justify-self: start;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;

      img {
        height: 40px;
      }
    }
  }
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    padding: 0;
  }
  .nav-item {
    margin: 6px;
    padding: 9px;
  }

  .nav-links {
    color: $primary-text;
    text-transform: capitalize;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    position: relative;
    &:hover {
      color: $hover-text;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 0%;
      height: 2px;
      background-color: $hover-text;
      transition: all 0.3s ease-in-out;
    }
    &:hover::before {
      width: 100%;
    }
  }

  .menu-icon {
    display: none;
  }
  .btn-navbar {
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    background-color: $btn-primary;
    border: 1px solid $btn-primary;
    border-radius: 9px;
    padding: 9px 25px;
    text-transform: capitalize;
    cursor: pointer;
    color: $btn-secondary;
    transition: 0.5s ease-in-out;
    font-size: 0.9rem;

    &:hover {
      background-color: $btn-hover;
      transition: 0.5s ease-in-out;
      color: $btn-secondary;
    }
    .btn-icon {
      width: 21px;
      height: 21px;
      margin-right: 9px;
      padding: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    .navbar-container {
      align-items: center;
      width: 90%;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 20px;
      border: none;
      border-radius: 0px;
      background-color: rgb(253, 252, 252); /* Optional background for menu */
      position: absolute;
      width: 100%;
      top: 100%; /* Position menu below navbar on small screens */
      left: 0;
      margin: 0;
      padding: 0;
      transition: all 0.3s ease-in-out;
      opacity: 0; /* Hide menu initially */
      visibility: hidden; /* Hide menu initially */
    }
    .nav-menu.active {
      opacity: 1; /* Show menu when active */
      visibility: visible; /* Show menu when active */
    }
    .menu-icon {
      display: flex; /* Show menu icon on small screens */
      color: $primary-text;
    }
    .btn-navbar {
      display: none;
    }

    .nav-item {
      margin: 0;
    }
    .btn-navbar {
      margin: 0;
    }
  }
}
