$text-primary: #cfc3ff;
$text-secondary: #fef9ff;
$icon-primary: #9584db;
$btn-primary: #57f785;
$btn-secondary: #002b0e;
$btn-hover: #12cb5f;
.section-promo {
  display: flex;
  justify-content: center;
  text-align: center;
  @media (max-width: 768px) {
    width: 90%;
    align-self: center;
    height: auto;
  }
  .container {
    display: flex;
    width: 90%;
    background: linear-gradient(-100deg, #180053 40%, #cfc3ff 100%);
    border-radius: 32px;
    padding: 40px 0;
    justify-content: center;
    gap: 0 2rem;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      padding: 1rem 0;
      align-items: center;
      background: linear-gradient(to top, #180053 40%, #cfc3ff 100%);
    }
    .flyer {
      width: 50%;
      @media (max-width: 768px) {
        width: 90%;
      }

      img {
        width: 70%;
        border-radius: 20px;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
    }
    .highlights {
      width: 50%;
      text-align: start;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      gap: 2rem;
      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
      }
      h4 {
        margin: 0;
        color: $text-primary;
        font-size: 3rem;
        font-weight: 500;
        font-family: "Montserrat";
        width: 90%;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 2rem;
          text-align: center;
        }
      }

      .msg-hook {
        display: flex;
        flex-wrap: wrap;
        gap: 4.063rem 2rem;
        width: 80%;
        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
          gap: 0;
        }
        .container-point {
          display: flex;
          flex-direction: column;
          width: calc(50% - 2rem);
          @media (max-width: 768px) {
            width: 60%;
          }
          .point-item {
            display: flex;
            gap: 6px;
            font-size: 1rem;
            margin-bottom: 10px;
            @media (max-width: 768px) {
              margin-bottom: 6px;
              width: fit-content;
            }
            .icon {
              width: 22px;
              height: 22px;
              color: $icon-primary;
            }
            span {
              color: $text-secondary;
              font-family: "Open Sans";
            }
          }
        }
        .tenant {
          display: flex;

          gap: 25px;
          width: 100%;
          flex-wrap: wrap;
          @media (max-width: 768px) {
            margin: 10px 0;
            width: 90%;
            align-items: center;
            justify-content: center;
          }
          .tenant-img {
            height: 40px;
          }
        }
        .container-btn {
          .term {
            color: $text-secondary;
            font-family: "Poppins";
            font-size: 0.7rem;
            margin-bottom: 9px;
            opacity: 0.5;
            @media (max-width: 768px) {
              text-align: center;
            }
          }
          .btn {
            display: flex;
            font-family: "Open Sans", sans-serif;
            font-weight: 500;
            background-color: $btn-primary;
            border: none;
            border-radius: 9px;
            padding: 9px 25px;
            cursor: pointer;
            color: $btn-secondary;
            transition: 0.5s ease-in-out;
            font-size: 0.9rem;
            align-items: center;
            &:hover {
              background-color: $btn-hover;
              transition: 0.5s ease-in-out;
              color: $btn-secondary;
            }
            .icon {
              margin-right: 9px;
              width: 21px;
              height: 21px;
            }
          }
        }
      }
    }
  }
}
