.aboutcont {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  .center {
    width: 80%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .about {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
      .capion {
        color: #ffffff;
        font-family: "Open Sans", sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
      }
      .title {
        color: #180054;
        font-family: "Montserrat", sans-serif;
        font-size: 3.8rem;
        font-weight: 500;
        margin: 4px 0 9px 0;
        @media (max-width: 768px) {
          font-size: 2.5rem;
        }
      }
      .des {
        width: 80%;
        color: #1c1b20;
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        @media (max-width: 768px) {
          width: 95%;
        }
      }
      .item {
        display: flex;
        justify-content: center;
        margin: 25px 0 40px 0;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .poin {
          text-align: left;
          ul {
            @media (max-width: 768px) {
              padding: 0;
              margin-left: 5px;
              margin: 0;
            }
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            color: #241913;
            list-style: none;
            li {
              margin: 0 0 9px 0;
              display: flex;
            }
          }
        }
      }
      .button {
        .wabutton {
          border: none;
          cursor: pointer;
          color: white;
          background-color: #6c3300;
          padding: 9px 25px;
          border-radius: 9px;
        }
      }
    }
    .aboutimg {
      padding: 40px 0;
      @media (max-width: 768px) {
        padding: 20px 0;
      }
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 25px;
        object-position: center;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .slick-slider {
        width: 100%;
        .slick-slide {
          margin: 0 20px;
          @media (max-width: 768px) {
            margin: 0 10px;
          }
        }
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
          color: #6c3300;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
          li button:before {
            color: #b85b00;
          }
          li.slick-active button:before {
            color: #b85b00;
          }
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
  }
}
