.section-lokasi {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
  .lokasi-title {
    margin: 0 0 40px 0;
    text-align: center;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 500;
    font-size: 3.7rem;
    color: #324454;
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
  .container-lokasi {
    display: flex;
    width: 80%;
    @media (max-width: 768px) {
      width: 90%;
      flex-direction: column;
    }
    .lokasi-img {
      width: 60%;
      @media (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .lokasi-point {
      background-color: #382778;
      width: 40%;
      @media (max-width: 768px) {
        width: 100%;
      }
      ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 20px;
        list-style: none;
        gap: 9px;
        li {
          display: flex;
          font-family: "Open Sans";
          color: #ffffff;
          @media (max-width: 768px) {
            font-size: 0.9rem;
          }
          .icon {
            margin-top: 2px;
            margin-right: 10px;
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }
}
